import React,{useState,useEffect} from 'react';
import { Line } from 'react-chartjs-2';
import Chart from '../../../components/Charts/Chart';

import _get from 'lodash/get';
const LineGraph=(props)=>{
  const [data,setData]=useState([])
  // const {assetUsageGraph}=API;
        useEffect(()=>{
            // assetUsageGraph().then((res)=>setData(res.data.data))
        },[])
       const arr =['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
       let LYTD=[]
       let YTD=[]
		const line = []
		const columns = [{ label: 'Trucks', title: 'Trucks'}]
        const excelDataOperator = [];
		data.ytd && data.ytd.map((y, i) => {
      YTD.push(y.usages)
			line.push({label:y.month, YTD:y.usages})
			columns.push({ label: y.month, title: y.month})
			const month = y.month
			if(i === 0){	
				y.assetWiseData.map((asset, i) => {
					excelDataOperator.push({ Trucks: asset.assetId})
				})
			}
			y.assetWiseData.map((asset, index)=>{
					arr.map((e)=>{
							if(e==month){
								excelDataOperator[index][e] = asset.used;
							}
					})
			})
		})
		data.lytd && data.lytd.map((l, index) => {
      line[index].LYTD = l.usages
      LYTD.push(l.usages)
    })
          let  chartData={
            labels: arr,
            datasets:
            [  
              {
                label:'LYTD',
                data:LYTD,
                backgroundColor:'transparent',
                borderColor: 'rgb(0, 208, 189)',
                borderWidth: 2
              },
              {
                label:'YTD',
                data:YTD,
                backgroundColor:'transparent',
                borderColor: 'rgb(38, 88, 240)',
                borderWidth: 2
              }
            ]
          }

    return(
       line.length!==0&& <Chart type='line' chartData={chartData}  location="Asset Usage" legendPosition="bottom"/>
    )
}
export default LineGraph;
